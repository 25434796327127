import { useQuery, useMutation, useQueryClient } from "react-query";
import { getFacultyRank, getFacultyRankByCCID } from "../components/Api/Getters";
import { postFacultyRank } from "../components/Api/Post";

export const useFacultyRank = (token) => {
	const queryOptions = {
		//refetchOnWindowFocus: false,
		staleTime: 1000 * 5,
		enabled: !!token,
	};
	return useQuery(
		["facultyRank", token],
		async () => {
			const facultyRank = await getFacultyRank(token);
			//group faculty rank objects by empid
			let groupedFacultyRank = {};
			facultyRank.forEach((facultyRankObj) => {
				if (!groupedFacultyRank[facultyRankObj.empid]) {
					groupedFacultyRank[facultyRankObj.empid] = [];
				}
				groupedFacultyRank[facultyRankObj.empid].push(facultyRankObj);
			});
			//if any of the groups has more than one object, delete all of them except for one
			Object.keys(groupedFacultyRank).forEach((empid) => {
				if (groupedFacultyRank[empid].length > 1) {
					// Sort the array so that objects with rank: "INSTRUCTOR" come last
					groupedFacultyRank[empid].sort((a, b) => (a.rank === "INSTRUCTOR" ? 1 : -1));

					// Delete all objects except for the first one
					groupedFacultyRank[empid] = groupedFacultyRank[empid].slice(0, 1);
				}
			});
			//deconstruct the grouped faculty rank object into an array
			let newFacultyRank = [];
			Object.keys(groupedFacultyRank).forEach((empid) => {
				newFacultyRank.push(groupedFacultyRank[empid][0]);
			});
			return newFacultyRank;
		},
		queryOptions
	);
};

export function useAddInstructorToFacultyRank(token, deptId) {
	const queryClient = useQueryClient();

	const mutation = useMutation(
		(instructorToAdd) => {
			instructorToAdd.dept_id = deptId;
			return postFacultyRank(token, instructorToAdd);
		},
		{
			onSuccess: async () => {
				// Invalidate and refetch the query
				await queryClient.invalidateQueries("facultyRank");
			},
		}
	);

	const addInstructorToFacultyRank = async (instructorToAdd) => {
		const response = await mutation.mutateAsync(instructorToAdd);

		if (response.status === 201) {
			// Wait for the invalidation and refetch to complete
			await queryClient.refetchQueries("facultyRank");
			return true;
		}

		return false;
	};

	return { addInstructorToFacultyRank, status: mutation.status };
}

export function useGetFacultyRankByCCID(token, ccid) {
	const queryOptions = {
		refetchOnWindowFocus: false,
		staleTime: 1000 * 5,
		enabled: !!token && ccid !== null && ccid !== undefined,
	};
	return useQuery(
		["getFacultyRank", token],
		async () => {
			const facultyRank = await getFacultyRankByCCID(token, ccid);
			if (facultyRank.length === 0) {
				return null;
			} else {
				const empId = facultyRank[0].empid;
				return empId;
			}
		},
		queryOptions
	);
}
