import React, { useState } from "react";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { Nav } from "../../components/Navbar/NavbarElements";
import logo from "../../images/UA_Logo_WHT_RGB.png";
import "./access.css";
import "../../App.css";
import axios from "axios";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default function SignIn(props) {
	const { setToken, disabled, setDisabled, loginError } = props;
	var [isError, setIsError] = useState(false);
	const [username, setUserName] = useState("");
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const type = showPassword ? "text" : "password";
	const [errorText, setErrorText] = useState("");
	const [successText, setSuccessText] = useState("");
	const [isSuccess, setIsSuccess] = useState(false);

	async function postLogin() {
		const lowerCaseUsername = username.toLowerCase();
		try {
			const result = await axios.post(process.env.REACT_APP_BACKEND_HOST.concat("/auth/login"), {
				username: lowerCaseUsername,
				password,
			});
			// Clear any previous error
			setIsError(false);
			setToken(result.data);
			setIsSuccess(true);
			setSuccessText("Authentication successful. Redirecting...");
		} catch (error) {
			console.error(error.response?.data || error.message);

			if (
				error.response?.data &&
				error.response?.data.non_field_errors[0] === "Incorrect Credentials"
			) {
				// Display a user-friendly error message
				setErrorText("Incorrect username or password. Please try again.");
				setIsError(true);
				setDisabled(false);
			} else {
				// Display a user-friendly error message
				setErrorText("An unknown error has occurred. Please contact TSQS.");
				setIsError(true);
				setDisabled(false);
			}
		}
	}

	async function handleKeypress(e) {
		if (e.key === "Enter") {
			setDisabled(true);
			await postLogin();
		}
	}

	async function handleLogin() {
		setDisabled(true);
		await postLogin();
	}

	const handleToggle = () => {
		setShowPassword(!showPassword);
	};

	return (
		<div className="app">
			<Nav
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<img src={logo} alt="UofA logo" height={50} width={183} />
			</Nav>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Container
					sx={{
						paddingTop: "100px",
						width: "500px",
						justifyContent: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Typography
							variant="h6"
							component="div"
							fontSize={16}
							fontWeight={600}
							fontFamily={"Roboto, sans-serif"}
						>
							Campus Computing ID
						</Typography>
						<Typography
							variant="h6"
							component="div"
							fontSize={32}
							fontWeight={700}
							fontFamily={"Roboto, sans-serif"}
						>
							UAlberta Login
						</Typography>
						<br />

						<form onKeyDown={handleKeypress}>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									gap: "10px",
									marginBottom: "10px",
									width: "450px",
								}}
							>
								<TextField
									type="text"
									size="small"
									autoComplete="username"
									placeholder="CCID"
									inputProps={{ style: { textAlign: "center" } }}
									InputProps={{ sx: { borderRadius: 0 } }}
									value={username}
									onChange={(e) => {
										setUserName(e.target.value);
									}}
								/>
								<div className="">
									<TextField
										size="small"
										type={type}
										className="w-full"
										autoComplete="current-password"
										inputProps={{ style: { textAlign: "center" } }}
										InputProps={{ sx: { borderRadius: 0 } }}
										placeholder="Password"
										value={password}
										onChange={(e) => {
											setPassword(e.target.value);
										}}
									/>
									<span
										className="flex justify-around items-center relative"
										onClick={handleToggle}
									>
										<VisibilityIcon className="absolute bottom-2 right-2 hover:cursor-pointer hover:text-green-800" />
									</span>
								</div>
							</Box>

							<Button
								disabled={disabled}
								variant="contained"
								sx={{
									width: "100%",
									fontWeight: 600,
									borderRadius: 0,
								}}
								onClick={handleLogin}
							>
								{!disabled && "LOGIN"}
								{disabled && !isSuccess && <CircularProgress size={22} />}
								{disabled && isSuccess && <CircularProgress size={22} />}
							</Button>
						</form>

						<br />
						<br />
						{isError && <p style={{ color: "red", textAlign: "center" }}>{errorText}</p>}

						{loginError && loginError.length > 0 && (
							<p style={{ color: "red", textAlign: "center" }}>{loginError}</p>
						)}
						<a
							href="https://ist.ualberta.ca/content/reset-forgotten-password"
							target="_"
							className="forgot-password"
						>
							Forgot your password?
						</a>
					</Box>
					<Container className="message-box">
						<p>
							If you have any questions about your reports or how to find your information, please
							email us at{" "}
							<a href="mailto:Web.Surveys@ualberta.ca" target="_" className="ua-link">
								Web.Surveys@ualberta.ca
							</a>
						</p>
						<p>
							Access to online SPOT information is restricted in accordance with the{" "}
							<a
								href="https://policiesonline.ualberta.ca/PoliciesProcedures/Procedures/Student-Input-to-the%20Evaluation-of-Teaching-and-Learning-Procedure.pdf"
								className="ua-link"
								target="_"
							>
								UAPPOL Policy
							</a>
						</p>
					</Container>
				</Container>
			</Box>
		</div>
	);
}

SignIn.propTypes = {
	setToken: PropTypes.func.isRequired,
};
